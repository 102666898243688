/* eslint-disable */
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { OFFLINE } from "./utils/apiData";
import "./App.scss";

import Page from "./Page";

const App = () => {
  if (OFFLINE) {
    return (
      <div className="offline">
        <h3>
          Hier entsteht die Internet-Präsenz von <br />
          Alexandra Dumitrescu
        </h3>
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <Router onUpdate={() => document.body.scrollTop = 0}>
          <Switch>
            <Route path="/">
              <Page />
            </Route>
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
};

export default App;
