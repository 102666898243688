import React, { useState } from "react";
import { nanoid } from "nanoid";
import "./Accordion.scss";

const Accordion = (props) => {
  const settings = props?.block?.settings;
  const children = props?.block?.children;

  const AccordionItems = () => {
    return children.map((item, id) => (
      <AccordionItemContent item={item} key={id} />
    ));
  };

  const AccordionItemContent = (props) => {
    const [open, setOpen] = useState(false);
    const thisID = nanoid(5)
    if (props.item.component === "accordionItem") {
      return (
        <div className="AccordionItem" id={thisID}>
          <b
            onClick={() => {

              const headline = document.querySelector("b.open")
              const item = document.querySelector(".itemContent.open")

              if(headline && item){
                headline.classList.add("close");
                headline.classList.remove("open");
                item.classList.add("close");
                item.classList.remove("open");
              }            
              setOpen(!open);
              document.getElementById(thisID).scrollIntoView();
            }}
            className={`headline ${open ? "open" : "close"}`}
          >
            {props.item.settings.headline}
          </b>
          <div
            className={`itemContent ${open ? "open" : "close"}`}
            dangerouslySetInnerHTML={{ __html: props.item.settings.content }}
          ></div>
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <div className={`Accordion ${settings.class}`} id={settings.id}>
      <AccordionItems />
    </div>
  );
};

export default Accordion;
