import React from 'react';
import Picture from '../Picture/Picture';
import './Image.scss';

const Image = (props) => {
  const settings = props.block.settings;

  if(props.block.settings.image){
    let path = props.block.settings.image.path;

    return (
      <div className={"Image "+settings.class}
            id={settings.id}>
         <Picture src={path} alt={settings.image.meta?.title} />
      </div>
    );
  }else{
    return (
      <div className={"Image "+settings.class}
      id={settings.id}>
         No Image set!
      </div>
    );
  }

};

export default Image;
